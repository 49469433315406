const routes = [
  {
    resource: "token",
    icon: "ShieldCheck",
    name: "Tokens",
    path: "token",
  },
  {
    resource: "dashboard",
    icon: "ComputerDesktop",
    name: "Dashboard",
    path: "dashboard",
  },
  {
    resource: "fee",
    icon: "CurrencyDollar",
    name: "HBAR Transaction Fee",
    path: "transaction-fee",
  },
  {
    resource: "wallet",
    icon: "Wallet",
    name: "Wallet",
    routes: [
      {
        icon: "Users",
        path: "account-holder",
        name: "Account Holders",
      },
      {
        icon: "ShoppingCart",
        path: "merchant",
        name: "Merchants",
      },
      {
        icon: "QueueList",
        path: "wallet-transaction",
        name: "Token Transactions",
      },
      // {
      //   icon: "ClipboardDocumentList",
      //   path: "settlement",
      //   name: "Settlements",
      // },
      // {
      //   icon: "CurrencyDollar",
      //   path: "transaction-fee",
      //   name: "Transaction Fee",
      // },
    ],
  },
  {
    resource: "liquidity",
    icon: "Briefcase",
    name: "Liquidity Partners",
    path: "liquidity",
  },
  {
    resource: "company",
    icon: "BuildingOffice",
    name: "Companies",
    path: "company",
  },
  {
    resource: "transaction",
    icon: "Banknotes",
    name: "Payment Transactions",
    path: "transaction",
  },
  {
    resource: "integration",
    icon: "Key",
    name: "Integrations",
    path: "integration",
  },
  {
    resource: "user",
    icon: "UserGroup",
    name: "User Management",
    routes: [
      {
        icon: "UserCircle",
        path: "user/account",
        name: "Account",
      },
      // {
      //   icon: "Key",
      //   path: "user/role",
      //   name: "Role",
      // },
    ],
  },
];

export default routes;
