export default {
  button: {
    primary: {
      base: "text-white bg-orange-500 border border-transparent",
      active:
        "active:bg-orange-500 hover:bg-orange-500 focus:ring focus:ring-orange-500",
      disabled: "opacity-50 cursor-not-allowed",
    },
    outline: {
      base: "text-orange-500 border-orange-500 border dark:text-orange-500 focus:outline-none",
      active:
        "active:bg-transparent hover:border-orange-500 focus:border-orange-500 active:text-orange-500 focus:ring focus:ring-orange-500",
      disabled: "opacity-50 cursor-not-allowed bg-orange-500",
    },
  },
  // Input
  input: {
    active:
      "focus:border-orange-500 border-orange-500 dark:orange-500 focus:ring focus:ring-orange-500 dark:focus:border-orange-500 dark:focus:ring-orange-500 dark:bg-gray-700",
    checkbox:
      "text-orange-500 form-checkbox focus:border-orange-500 focus:outline-none focus:ring focus:ring-orange-500 focus:ring-offset-0 rounded dark:focus:ring-orange-500 ",
    radio:
      "text-orange-500 form-radio dark:border-orange-500 focus:border-orange-500 focus:outline-none focus:ring focus:ring-orange-500 focus:ring-offset-0",
  },
};
