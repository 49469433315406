import pages from "../pages/index";

const routes = [
  // Token
  {
    resource: "token",
    path: "token",
    element: pages.Token,
    children: [
      {
        path: "new",
        element: pages.CreateToken,
      },
      {
        path: ":id",
        element: pages.TokenDetails,
      },
    ],
  },
  // Dashboard
  {
    resource: "dashboard",
    path: "dashboard",
    element: pages.TokenDashboard,
  },
  // Transaction Fee
  {
    resource: "fee",
    path: "transaction-fee",
    element: pages.TransactionFee,
  },
  //   Wallet
  {
    resource: "wallet",
    path: "account-holder",
    element: pages.AccountHolderWallet,
    children: [
      {
        path: ":id",
        element: pages.AccountHolderDetails,
      },
    ],
  },
  {
    resource: "wallet",
    path: "merchant",
    element: pages.MerchantWallet,
    children: [
      {
        path: "new",
        element: pages.CreateMerchant,
      },
      {
        path: ":id",
        element: pages.MerchantDetails,
      },
    ],
  },
  {
    resource: "wallet",
    path: "settlement",
    element: pages.WalletSettlement,
  },
  {
    resource: "wallet",
    path: "wallet-transaction",
    element: pages.WalletTransactions,
  },

  //   Company
  {
    resource: "company",
    path: "company",
    element: pages.Company,
    children: [
      {
        path: "new",
        element: pages.CreateCompany,
      },
      {
        path: ":id",
        element: pages.CompanyDetails,
      },
    ],
  },
  //   Transaction
  {
    resource: "transaction",
    path: "transaction",
    element: pages.Transaction,
  },
  //   Integration
  {
    resource: "integration",
    path: "integration",
    element: pages.Integration,
    children: [
      {
        path: "new",
        element: pages.CreateIntegration,
      },
      {
        path: ":id",
        element: pages.IntegrationDetails,
      },
    ],
  },
  //   User Management
  {
    resource: "user",
    path: "user",
    element: pages.Account,
    children: [
      {
        path: "account",
        element: pages.Account,
      },
      {
        path: "new",
        element: pages.CreateAccount,
      },
      {
        path: "account/:id",
        element: pages.AccountDetails,
      },
      // {
      //   path: "role",
      //   element: pages.Role,
      // },
    ],
  },
  // Liquidity
  {
    resource: "liquidity",
    path: "liquidity",
    element: pages.Liquidity,
    children: [
      {
        path: "new",
        element: pages.CreateLP,
      },
      {
        path: ":id",
        element: pages.LiquidityDetails,
      },
    ],
  },
];

export default routes;
