import { lazy } from "react";

const pages = {
  // Login
  Login: lazy(() => import("./Login")),
  ForgotPassword: lazy(() => import("./ForgotPassword")),
  ChangePassword: lazy(() => import("./ChangePassword")),
  // Token
  Token: lazy(() => import("./Token")),
  CreateToken: lazy(() => import("./Token/CreateToken")),
  TokenDetails: lazy(() => import("./Token/TokenDetails")),
  // Dashboard
  TokenDashboard: lazy(() => import("./Dashboard")),
  TransactionFee: lazy(() => import("./TransactionFee")),
  // Acc Holder
  AccountHolderWallet: lazy(() => import("./Wallet/AccountHolder")),
  AccountHolderDetails: lazy(() =>
    import("./Wallet/AccountHolder/AccHolderDetails")
  ),
  // Wallet
  WalletSettlement: lazy(() => import("./Wallet/SettlementTransaction")),
  WalletTransactions: lazy(() => import("./Wallet/Transaction")),
  // Merchant
  MerchantWallet: lazy(() => import("./Wallet/Merchant/index")),
  CreateMerchant: lazy(() => import("./Wallet/Merchant/CreateMerchant")),
  MerchantDetails: lazy(() => import("./Wallet/Merchant/MerchantDetails")),
  // Company
  Company: lazy(() => import("./Company")),
  CreateCompany: lazy(() => import("./Company/CreateCompany")),
  CompanyDetails: lazy(() => import("./Company/CompanyDetails")),
  // Transaction
  Transaction: lazy(() => import("./Transaction")),
  // Integration
  Integration: lazy(() => import("./Integration")),
  CreateIntegration: lazy(() => import("./Integration/CreateIntegration")),
  IntegrationDetails: lazy(() => import("./Integration/IntegrationDetails")),
  // User Account
  Account: lazy(() => import("./User/Account")),
  CreateAccount: lazy(() => import("./User/CreateAccount")),
  AccountDetails: lazy(() => import("./User/AccountDetails")),
  // User Role
  Role: lazy(() => import("./User/Role")),
  // Liquidity
  Liquidity: lazy(() => import("./Liquidity")),
  CreateLP: lazy(() => import("./Liquidity/CreateLP")),
  LiquidityDetails: lazy(() => import("./Liquidity/LiquidityPartnerDetails")),
};
export default pages;
